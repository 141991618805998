import React from 'react'
import LayoutContainer from 'components/templates/premium/t/Layout'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'
import AvatarImg from 'assets/images/premium/t-black/avatar.jpeg'
import { BusinessProfileType, PremiumTemplates, SocialMedia } from 'utils/constants'
import { toContentfulRaw } from 'utils/format'

const defaultData: PremiumUserArgs = {
  isDemo: true,
  id: '1',
  name: 'Paul',
  firstLastName: 'Miranda',
  nameToDisplay: 'Paul Miranda',
  avatarUrl: {
    file: {
      url: AvatarImg,
    },
  },
  businessProfileType: BusinessProfileType.Personal,
  template: PremiumTemplates.TStyle_Black,
  metadata: {
    pageTitle: 'Professional TBlack',
  },
  jobs: [
    {
      id: '1',
      title: 'Gestor de Contenidos',
    },
  ],
  contact: {
    id: '1',
    phoneNumbers: ['+51901422305'],
    email: 'hola@playu.pe',
    addresses: [
      {
        id: '1',
        description: {
          description: 'Lima, Peru',
        },
      },
    ],
    socialMedia: [
      {
        order: 1,
        id: '1',
        link: 'https://instagram.com/playu.shop',
        description: {
          description: 'Sígueme en Instragram',
        },
        type: SocialMedia.Instagram,
      },
      {
        order: 2,
        id: '2',
        link: '#',
        description: {
          description: 'Conectemos en LinkedIn',
        },
        type: SocialMedia.Linkedin,
      },
      {
        order: 3,
        id: '3',
        link: 'https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU.',
        description: {
          description: 'Conversemos por Whatsapp',
        },
        type: SocialMedia.Whatsapp,
      },
      {
        order: 4,
        id: '4',
        link: 'https://www.facebook.com/playu.shop',
        description: {
          description: 'Sígueme en Facebook',
        },
        type: SocialMedia.Facebook,
      },
      {
        order: 5,
        id: '5',
        link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
        description: {
          description: 'Visita nuestro Canal',
        },
        type: SocialMedia.Youtube,
      },
      {
        order: 6,
        id: '6',
        link: 'https://www.tiktok.com/@playu.shop',
        description: {
          description: 'Sígueme en TikTok',
        },
        type: SocialMedia.Tiktok,
      },
    ],
  },
  services: [
    {
      id: '1',
      icon: 'far fa-lightbulb',
      title: 'Producción de Contenido',
      description: {
        raw: toContentfulRaw(
          'Desarrollo de piezas gráficas, visuales y multimedia las cuales pueden ser usadas como contenido digital y/o leads para campañas'
        ),
      },
    },
    {
      id: '2',
      icon: 'far fa-window-maximize',
      title: 'Community Management',
      description: {
        raw: toContentfulRaw(
          'Me encargo de la interacción con tu comunidad en tus redes sociales asi como el engagement con potenciales clientes'
        ),
      },
    },
    {
      id: '3',
      icon: 'fas fa-award',
      title: 'Posicionamiento y SEO',
      description: {
        raw: toContentfulRaw(
          'Me encargo de que tu marca se posicione primero en las busquedas de google así como de brindar insight de las interaccions de tus usuarios'
        ),
      },
    },
  ],
}

const PageComponent: React.FC = () => (
  <LayoutContainer user={new PremiumUser(defaultData)} template={PremiumTemplates.TStyle_Black} />
)

export default PageComponent
